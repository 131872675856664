import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import ArticlePreview from '../components/article-preview';

class KalenderPage extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulEvent.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Kalender" />
        <Hero title="Kalender" />
        <ArticlePreview posts={posts} type="event" />
      </Layout>
    )
  }
}

export default KalenderPage;

export const pageQuery = graphql`
  query EventIndexQuery {
    allContentfulEvent(sort: { fields: [startDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY-MM-DD")
        startDate(formatString: "YYYY-MM-DD")
        stopDate(formatString: "YYYY-MM-DD")
        tags
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
  }
`
